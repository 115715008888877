@use '../../styles/' as s;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
    padding: 2rem;
    -webkit-filter: drop-shadow(5px 10px 20px #dddcdc);
    filter: drop-shadow(5px 10px 20px #dddcdc);
  }

  .wrapper {
    position: absolute;
    left: 15%;
    bottom: 25%;
    padding: 1rem;
    box-shadow: inset 0px 0px 50px #fff ;
    border-radius: 20px;
    z-index: 2;
  }
  .title {
    width: 100%;
    font-size: 6rem;
    font-family: s.$font-title;
    color: s.$primary-color;
    margin: .2rem;
    z-index: 2;
  }
  
  .text {
    font-family: s.$font-header;
    width: 100%;
    font-size: 2rem;
    z-index: 2;
  }

  .title, .text {
    margin: 5px;
    padding: 5px
  }
}

@media only screen and (max-width: 700px) {
  .container .wrapper  {
    margin: 2rem auto 0;
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container .image {
    margin: -30px auto 2rem;
  }
}