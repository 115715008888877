@use '../../../../styles/' as s;

// variables for calculating the height of the Selector container
$row-gap: 2px;
$li-padding: 5px;
$li-line-height: 15px;

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: top;
  font-size: 5px;
  border: 2px solid s.$secondary-color;
  border-radius: 5px;
  overflow: hidden;
  height: auto;
  position: absolute;
  background-color: s.$bg-white;
  z-index: 100;
}

.scroller {
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  align-items: center;
}

ul.options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: $row-gap;
  padding: 0;
  margin: 0;
  list-style: none;
  // Height set to only show 3 values at one time
  height: calc(($li-line-height + ($li-padding * 2) + $row-gap + 2px) * 3);
  overflow: hidden;
  // overflow-y: scroll;

  // Transition for the transform property
  & > li {
    transition: all 0.5s ease-in-out;
  }

  &.HourPosition > li {
    transform: translateY(calc(var(--hour-position) * ($li-line-height + ($li-padding * 2) + $row-gap)));
  }

  &.MinutePosition > li {
    transform: translateY(calc(var(--minute-position) * ($li-line-height + ($li-padding * 2) + $row-gap)));
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 50px;
    padding: $li-padding; // used in calculating the height of the container
    line-height: $li-line-height; // set to pixels to help calculate the height of the container
    border-radius: s.$border-radius;
    cursor: pointer;
    transition: 
      background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s ease-in-out;

    

    &.active {
      // background-color: s.$bg-dark;
      border: 2px solid s.$secondary-color;
    }

    &:hover {
      background-color: s.$bg-light;
      color: white;
    }
  }
}

$ArrowMargin: 5px 0;

.ArrowUp {
  @include s.baseArrow;
  transform: rotate(90deg);
  margin: $ArrowMargin;
}

.ArrowDown {
  @include s.baseArrow;
  transform: rotate(-90deg);
  margin: $ArrowMargin;
}