@use "../../../styles/" as s;

$toggle-radius: 2px;

.inputBox {
	@include s.inputComponent;
	height: calc(s.$input-height + 1px);
	cursor: pointer;
}

.input {
	@include s.inputElement;
	display: flex;
	flex-direction: row;
	appearance: none;
	padding: 0;

	&::before,
	&::after {
		display: flex;
		width: 50%;
		justify-content: center;
		align-items: center;
		background-color: s.$bg-white;
		border-radius: s.$input-radius;
		transition: 0.3s ease all;
		cursor: pointer;
	}
}

.input::before {
	content: attr(data-false); // set by component params
	background-color: s.$secondary-color;
	color: white;
	border-radius: $toggle-radius 0 0 $toggle-radius;
}

.input::after {
	content: attr(data-true); // set by component params
	color: s.$light-pink;
	border-radius: 0 s.$input-radius s.$input-radius 0;
}

.input:checked::before {
	color: s.$light-pink;
	background-color: white;
	border-radius: s.$input-radius 0 0 s.$input-radius;
}

.input:checked::after {
	color: white;
	background-color: s.$secondary-color;
	border-radius: 0 $toggle-radius $toggle-radius 0;
}

.input:focus-visible {
	outline-offset: -2px;
	outline: 2px solid s.$outline-color;
}

// example of code: https://jsfiddle.net/6dwbfhkc/96/
