@use "../../styles/" as s;

.page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.container {
	display: flex;
	flex-direction: column;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 15px;
	justify-content: start;
	max-width: 100%;
	margin: 1rem 2rem;
}

.h1 {
	font-family: s.$font-header;
	font-weight: 600;
	font-size: 4em;
	color: s.$primary-color;
	margin: 3rem 0 2rem 0;
	padding: 0;
}

.h2 {
	font-family: s.$font-header;
	font-weight: 600;
	font-size: 2.5rem;
	color: s.$primary-color;
	margin: 2rem 0 0 0;
}

.subtext {
	font-weight: 400;
	font-size: 1.2rem;
	color: s.$text-color;
	margin: 0.5rem 2rem;

	span {
		font-weight: 800;
		font-size: inherit;
		font-style: italic;
		color: inherit;
		text-decoration: underline;
		color: s.$primary-color;
	}
}

.sectionText {
	font-weight: 800;
	font-size: 1rem;
	margin: 0;

	span {
		font-weight: 800;
		font-size: inherit;
		font-style: italic;
		color: inherit;
		text-decoration: underline;
		padding: 0 5px 0 0;
		color: s.$primary-color;
	}
}
