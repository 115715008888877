// color palette from https://coolors.co/e8868e-f0b1b6-eae8dc-f1f0e8-eee0c9
$dark-pink: #e8868e;
$light-pink: #f0b1b6;
$dark-cream: #eae8dc;
$light-cream: #f1f0e8;
$beige: #eee0c9;
$white: #ffffff;
$black: #000;

// website colors
$primary-color: $dark-pink;
$secondary-color: $light-pink;
$accent-color: $beige;

// font colors
$title-color: $dark-pink;
$subtitle-color: $light-pink;
$text-color: $black;

// background colors
$bg-white: $white;
$bg-light: $light-pink;
$bg-dark: $dark-pink;
$bg-off-white: #f9f9f9;

// semantic colors
$success-color: #28a745;
$error-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
$outline-color: $accent-color;
