@use "../../styles/" as s;

.container {
	margin-bottom: 5rem;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	font-size: 4rem;
	text-align: center;
	font-family: s.$font-header;
	color: s.$primary-color;
	margin: 3rem auto;
}

.separator {
	border: 2px solid s.$light-pink;
	border-radius: 20px;
	margin: 2rem auto;
	width: 70%;
}

// consolidate content to the center of the screen on
// large screens by increasing the container margin
@media only screen and (min-width: 1400px) {
	.container {
		margin: 0 15%;
	}
}

// un-squish the content by reducing the container
// margin and decreasing the separator margin
@media only screen and (max-width: 600px) {
	.container {
		margin: 0;
	}

	.separator {
		margin: 2rem auto;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		margin: 0;
		padding: 0;
	}
}
