@use './styles/' as s;
@use './styles/base/global.scss';
@use './styles/base/normalize.scss';

html,
body,
#root {
	height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
}
