@use "../styles/" as s;

h1,
h2,
h3,
h4 {
	font-family: s.$font-body;
	color: s.$text-color;
}

.App {
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-rows: 1.5fr 40fr 2fr;
	justify-items: center;
}

header {
	width: 100vw;
	height: 60px;
	display: flex;
	align-items: flex-end;
	overflow: hidden;
	padding-bottom: 6px;

	.titleWrapper {
		margin: 0 1em;
		padding: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	.titleContainer {
		display: flex;
		align-items: center;
	}

	.logo {
		padding: 0.25em;
		margin: 0 0.15em;
		width: 3em;
		height: 3em;
	}

	#navbar {
		flex: 1 1 80%;
	}
}

#content {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
}

.title {
	font-family: s.$font-title;
	font-weight: 600;
	font-size: 2em;
	padding: 0;
	margin: 0;

	.embolden {
		font-weight: 800;
		color: s.$dark-pink;
		font-family: inherit;
		font-size: inherit;
	}
}

.subtext {
	color: s.$dark-pink;
	font-weight: 800;
	font-size: 0.8em;
	// font-style: italic;
	padding: 0.25em;
	margin: 0;
}

footer {
	background-color: s.$light-pink;
	width: 100vw;
	height: auto;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
}

.social {
	padding: 1em;
	a {
		margin: 1em;
	}
	* {
		color: white;
	}
}

@media only screen and (max-width: 600px) {
	header {
		// adjust header for mobile
		position: relative;
		display: flex;
		height: 60px;

		.title {
			font-size: 1.2em;
			position: absolute;
		}

		.titleContainer h2:nth-child(1),
		.titleContainer h2:nth-child(3) {
			position: absolute;
			left: 4em;
		}

		.titleContainer h2:nth-child(1) {
			top: 0.8em;
		}

		.titleContainer h2:nth-child(3) {
			top: 1.8em;
		}

		.logo {
			width: 3em;
			height: auto;
			padding: 0;
			margin-bottom: 2px;
		}

		.titleContainer {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#navbar {
			justify-content: center;
		}
	}
}

@media only screen and (max-width: 500px) {
	header {
		// adjust header for mobile
		position: relative;
		display: flex;
		height: 60px;

		.title {
			font-size: 1.2em;
			position: absolute;
		}

		.titleContainer h2:nth-child(1),
		.titleContainer h2:nth-child(3) {
			display: none;
		}

		.logo {
			width: 3em;
			height: auto;
			padding: 0;
			margin-bottom: 2px;
		}

		.titleContainer {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#navbar {
			justify-content: center;
		}
	}
}
