@use "../../styles/" as s;

.page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 15px;
	justify-content: start;
	max-width: 100%;
	margin: 1rem 2rem;
}

.h1 {
	font-family: s.$font-header;
	font-weight: 600;
	font-size: 4em;
	color: s.$primary-color;
	margin: 3rem 0 0 0;
	padding: 0;
}
