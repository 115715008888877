@use '../../../../styles/' as s;

$arrowSize: 2em;

.ArrowLeft {
  @include s.baseArrow;
  left: $arrowSize;
}

.ArrowRight {
  @include s.baseArrow;
  right: $arrowSize;
}

.container {
  font-size: 5px;
  border: 2px solid s.$secondary-color;
  border-radius: 5px;
  // overflow: hidden;
  height: auto ;
  position: absolute;
  background-color: s.$bg-white;
  z-index: 100;
}

.CalendarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: s.$bg-white;
  border-radius: s.$input-radius s.$input-radius 0 0;
}

.CalendarGrid {
  display: grid;
  grid-template: repeat(7, auto) / repeat(7, 1fr);
}

.CalendarMonth {
  font-weight: 500;
  font-size: 1.2em;
  color: s.$primary-color;
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
  background-color: s.$bg-white;
}

.CalendarCell {
  @include s.calendarCell;
  // grid-column: set in renderDayLabel using 'style' object
}

.CalendarDay {
  @include s.calendarCell;
  font-size: 1em;
  color: black !important;
  border-top: 2px solid s.$secondary-color;
  border-bottom: 2px solid s.$secondary-color;
  // border-right: set in renderDayLabel using 'style' object
  // grid-column: set in renderDayLabel using 'style' object
}

.CalendarDate {
  @include s.calendarCell;
  @include s.calendarDate;
  // font-weight: set in renderDayLabel using 'style' object
  // border-bottom: set in renderDayLabel using 'style' object
  // border-right: set in renderDayLabel using 'style' object
  // color: set in renderDayLabel using 'style' object
  // grid-row: set in renderDayLabel using 'style' object
}

.CalendarDate::before {
  border-radius: 5px;
}

.HighlightedCalendarDate {
  @include s.calendarDate;
  color: #fff !important;
  background: s.$primary-color !important;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 2px solid s.$primary-color;
    border-radius: var(--highlighted-border-radius); // custom variable set in Calendar/components.js -> HighlightedCalendarDate()
  }
}

.TodayCalendarDate {
  @include s.calendarDate;
  color: #fff !important;
  background: #06c !important;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 2px solid s.$primary-color;
    border-radius: var(--today-border-radius); // custom variable set in Calendar/components.js -> TodayCalendarDate()
  }
  color: s.$primary-color !important;
  background: transparent !important;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0.75em solid s.$primary-color;
    border-left: 0.75em solid transparent;
    border-top: 0.75em solid transparent;
  }
  &:hover {
    color: s.$primary-color !important;
    background: rgba(240, 182, 177, 0.25);
  }
}

.BlockedCalendarDate {
  @include s.calendarDate;
  color: black !important;
  background: gray !important;
  position: relative;
  &:hover {
    color: black !important;
    background: gray !important;
    border-color:gray;
        cursor:default;
  }
}

@media only screen and (min-width: s.$desktop) {
  // insert classes here
}

@media only screen and (min-width: s.$tablet) {
  .CalendarMonth {
    font-size: 1em;
  }
  
  .CalendarDate, .HighlightedCalendarDate, .TodayCalendarDate {
    font-size: .8em;
  }

  .CalendarDay {
    font-size: .7em;
  }

}

@media only screen and (max-width: s.$tablet) {
  .CalendarMonth {
    font-size: 1em;
  }
  
  .CalendarDate, .HighlightedCalendarDate, .TodayCalendarDate {
    font-size: .9em;
  }

  .CalendarDay {
    font-size: .7em;
  }

}