@use '../../order/input/Input.module.scss';
@use '../../../styles/' as s;


.inputBox {
  background-color: s.$bg-white;
}


.inputBox:focus-within > .inputLabel,
.inputBox.active > .inputLabel {
  top: -6px;
  left: 8px;
  padding: 1px 5px;
  font-size: 0.7em;
  background-color: s.$bg-white;
  color: s.$primary-color;
  font-weight: 800;
}