@use "../../styles/" as s;

.card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: left;
	border: 2px solid s.$primary-color;
	border-radius: 10px;
	padding: 10px 20px;
	margin: 10px;
	width: 250px; // MUST match the grid-template-columns value in cakes.module.scss
	min-height: 320px;
	max-width: 250px;
	transition:
		border-color 0.5s ease-in-out,
		background-color 0.5s ease-in-out,
		transform 0.5s ease-in-out,
		color 0.5s ease-in-out;

	.h1 {
		align-self: flex-start;
		justify-self: flex-start;
	}

	.h2 {
		margin: 0.25rem 0;
		padding: 0;
		font-family: s.$font-title;
		color: s.$primary-color;
		font-weight: 800;
		font-size: 1.8em;
		transition: color 0.5s ease-in-out;
	}

	.title {
		font-weight: 800;
		font-size: 0.8rem;
		color: s.$primary-color;
		margin: 0.25rem 0 0.125rem 0;
		padding: 0;
		transition: color 0.5s ease-in-out;
	}

	.value {
		margin: 0;
		padding: 0 0 0.5rem 0;
		transition: color 0.5s ease-in-out;
		font-size: 1.1rem;
	}

	.button {
		background-color: s.$primary-color;
		color: white;
		border: none;
		border-radius: 5px;
		padding: 0.5rem 1rem;
		font-size: 1rem;
		cursor: pointer;
		margin-top: 1rem;
		transition:
			background-color 0.5s ease-in-out,
			color 0.5s ease-in-out,
			transform 0.5s ease-in-out;
	}

	&.default {
		border-color: lightpink;
	}

	&.hovered {
		border: 2px solid s.$secondary-color;
		background-color: s.$secondary-color;
		& .title {
			color: s.$primary-color;
		}
		& .h2,
		.value {
			color: white;
		}
		& .button {
			background-color: white;
			color: black;
		}

		& .button:hover {
			transform: scale(1.05);
		}
		transform: scale(1.05);
	}

	&.notHovered {
		border-color: lightgray;
		& .h2,
		& .title,
		& .value {
			color: lightgray;
		}
		& .button {
			background-color: lightgray;
		}
	}
}
