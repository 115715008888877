@use '../../../../styles/' as s;

.container {
  position: relative;
  display: flex;
  flex-basis: 80%;
  // height: 80%;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
}

.photo {
  width: 100%;
  height: 100%;
  &.loading {
    @include s.loading;
  }

  &.active {
    background-color: s.$secondary-color;
  }

  &.inactive {
    display: none;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: s.$photo-image-height;
  min-height: s.$photo-image-height;
  padding: 0;
  margin: 0 0 -5px 0;
  opacity: 0;
  transition: 
    opacity 1s ease-in-out;

  &.loaded {
    opacity: 1;
  }
}

.insetShadow {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  box-shadow: inset 0px 0px 10px -5px black, inset 0px -1px 10px -5px;
  z-index: 2;
}

.expander {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px;
  font-weight: 800;
  z-index: 2;
  cursor: pointer;

  & > path {
    fill: s.$white;
  }

  &:hover {
    background-color: s.$primary-color;
    border-bottom-left-radius: s.$photo-card-border-radius;
  }
}

.arrow {
  @include s.baseArrow;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 800;
  background-color: transparent;
  z-index: 5;

  & > path {
    fill: s.$white;
  }

  &.left {
    left: 2px;
  }

  &.right {
    right: 2px;
  }

  &:hover {
    background-color: s.$primary-color;
    border-color: s.$primary-color;
    cursor: pointer;
  }
}

.indicators {
  display: flex;
  position: absolute;
  bottom: .5rem;
  z-index: 3;
}

.indicator {
  margin: 0 .25rem;
  padding: 0;
  height: .5rem;
  width: .5rem;
  background-color: s.$white;
  box-shadow: s.$photo-card-box-shadow;
  outline: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all .25s ease-out;

  &.inactive {
    background-color: grey;
  }
}

@media only screen and (max-width: s.$tablet) {
  .container {
    flex-basis: 75%;
  }
}