@use "../../../styles/" as s;

.accordion {
  display: grid;
  grid-template-rows: min-content 0fr;
  transition: grid-template-rows 500ms;
  border: none;

  &.open {
    grid-template-rows: min-content 1fr;
  }
}

.accordionBar {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: s.$primary-color;
  font-weight: bolder;
  background-color: s.$secondary-color;
  border-radius: s.$input-radius s.$input-radius 0 0;
  padding: 10px 0;
  cursor: pointer;
}

.accordionTitle {
  text-align: center;
  width: 100%;
  color: s.$white;
}

.chevron {
  position: absolute;
  left: 20px;
  transition: 0.3s ease all;
  color: s.$white;

  & path {
    fill: s.$white;
  }
}

.accordion.open .accordionBar .chevron,
.accordion:focus-within .accordionBar .chevron {
  transform: rotate(90deg);
}

.expander {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: s.$mobile) {
  .expander {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 5px;
  }
}

