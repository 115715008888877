@use "../../../styles/" as s;

.photos {
	grid-area: photo;
	margin: 0 2rem;
	justify-self: center;
}

.content {
	height: fit-content;
	grid-area: content;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	row-gap: 15px;
}

.subtitle,
.title,
.text,
.link {
	margin: 0 25px;
}

.subtitle {
	font-family: s.$font-header;
	font-size: 2rem;
	padding: 0;
	margin-bottom: -20px;
}

.title {
	font-family: s.$font-title;
	font-size: 5rem;
	color: s.$primary-color;
	padding: 0;
}

.text {
	padding: 0;
	line-height: 1.5;
}

.link {
	width: max-content;
	padding: 0;
}

.button {
	border: 2px solid s.$dark-pink;
	border-radius: s.$input-radius;
	background-color: s.$dark-pink;
	color: white;
	font-weight: 600;
	padding: 1em 2.5em;
	transition: 0.25s;
	width: 100%;

	&:hover,
	&:focus {
		border-color: s.$light-pink;
		background-color: white;
		color: s.$dark-pink;
		font-weight: 800;
		transition: 0.25s;
		scale: 1.025;
		cursor: pointer;
	}

	&:active {
		background-color: white;
		color: s.$dark-pink;
		transition: 2;
		scale: 1;

		a {
			color: s.$dark-pink;
		}
	}

	a {
		text-decoration: none;
		color: white;
		outline: none;
	}
}

.section {
	text-align: justify;
	display: grid;
	grid-template-rows: repeat(3, min-content [col-start]);
	column-gap: 20px;
	min-height: fit-content;
	margin: 4rem;
	max-width: 700px;
}

.section.left {
	text-align: left;
	align-content: center;
	grid-template-columns: 1fr 3fr;
	grid-template-areas:
		"photo content"
		"photo content"
		"photo content"
		"photo content";
	.photos {
		justify-self: right;
	}
}

.section.right {
	grid-template-columns: 3fr 1fr;
	grid-template-areas:
		"content photo"
		"content photo"
		"content photo"
		"content photo";

	.photos {
		justify-self: left;
	}

	.title,
	.subtitle {
		text-align: right;
	}

	.link {
		align-self: flex-end;
	}
}

// set to 580px —- when the container and separator margins
// start causing the header to show whitespace on the sides
@media only screen and (max-width: 800px) {
	.section,
	.section.right,
	.section.left {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		row-gap: 2rem;
		align-items: center;
		text-align: center;

		.link {
			align-self: center;
		}
	}

	.photos {
		justify-self: center;
		margin-bottom: 2rem;
	}

	.content {
		row-gap: 2rem;
		align-items: center;
		max-width: 500px;

		.subtitle {
			margin-bottom: -2.5rem;
		}
	}

	.separator {
		margin: 5rem auto;
	}
}

@media only screen and (max-width: 600px) {
	.section {
		margin: 0;
		padding: 0;
	}

	.title {
		font-size: 3.8rem;
		margin: 0;
		padding: 0;
	}

	.text {
		margin: 0 2rem;
		text-align: justify;
	}

	.link {
		margin-bottom: 2rem;
	}
}
