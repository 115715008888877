// containers & inputs
$input-radius: 5px;
$input-height: 35px;
$input-size: 14px;
$input-padding: 4px 5px 0 10px;
$input-border: 2px solid $secondary-color;
$box-shadow-radius: $input-radius;
$border-radius: 5px;

// grid layout
$gap: 0.25em;

// PhotoCard variables
$pcs-height: 325px;
$pcs-width: 200px;
$pis-height: 235px;
$pcs-font-size: 2em;
$scale-percentage: 0.9;
$photo-card-padding: 1em;
$photo-card-height: calc($pcs-height * $scale-percentage);
$photo-card-width: calc($pcs-width * $scale-percentage);
$photo-card-border-radius: $box-shadow-radius;
$photo-card-box-shadow: 0 0 5px 0 rgba(240, 177, 182, 0.4);
$photo-image-height: calc($pis-height * $scale-percentage);
$photo-card-font-size: calc($pcs-font-size * $scale-percentage - 0.25em);

// Media Queries
$desktop: 750px;
$tablet: 500px;
$mobile: 450px;
