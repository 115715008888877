@mixin inputComponent {
	visibility: visible;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	margin: 4px 0;
	height: $input-height;
	z-index: 0;
}

@mixin inputElement {
	margin: 0;
	padding: $input-padding;
	height: $input-height;
	width: auto;
	font-size: 14px;
	border: $input-border;
	border-radius: $input-radius;

	@media only screen and (max-width: $tablet) {
		font-size: 16px;
	}
}

@mixin inputLabel {
	position: absolute;
	top: 10px;
	left: 12px;
	color: $secondary-color;
	border-radius: $input-radius;
	z-index: 1;
}

@mixin inputError {
	position: absolute;
	bottom: -6px;
	right: 20px;
	font-size: 0.7em;
	color: red;
	font-style: italic;
	padding: 1px 5px;
	background-color: white;
	border-radius: 3px;
}

@mixin scrollbar {
	/* For browsers that support `scrollbar-*` properties */
	@supports (scrollbar-color: auto) {
		scrollbar-color: $secondary-color $white;
	}

	/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
	@supports selector(::-webkit-scrollbar) {
		&::-webkit-scrollbar {
			background: $white;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: $input-radius;
			background: $secondary-color;
			width: 10px;
		}
	}
}

$arrowBorder: 0.2em;

@mixin baseArrow {
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 4px 7px;
	border: 1px solid $white;
	background-color: $white;
	border-radius: 50%;
	transition: all 0.25s ease-out;
	outline: none !important;
	user-select: none;
	cursor: pointer;

	& path {
		fill: $secondary-color;
	}
	&:hover {
		border: 1px solid $secondary-color;
		background-color: $secondary-color;

		& path {
			fill: $white;
		}
	}
}

@mixin calendarCell {
	text-align: center;
	align-self: center;
	letter-spacing: 0.1rem;
	padding: 0.6em 0.25em;
	user-select: none;
	background-color: $white;
	font-weight: 800;
}

@mixin calendarDate {
	@include calendarCell;
	background-color: $bg-white;
	font-size: 1em;
	cursor: pointer;
	transition: all 0.4s ease-out;
	&:hover {
		color: $secondary-color;
		background: rgba(240, 182, 177, 0.25);
	}
}

@mixin loading {
	background-size: 400% 400%;
	background-image: linear-gradient(
		-45deg,
		$primary-color,
		$secondary-color,
		$dark-cream,
		$white
	);
	animation: gradient-animation 3s ease infinite;

	/* Define the animation */
	@keyframes gradient-animation {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}

@mixin primaryButton {
	border-radius: 0 0 0.35em 0.35em;
	margin: 0;
	padding: 0;
	border: 1px solid $dark-pink;
	background-color: $dark-pink;
	color: white;
	font-weight: bolder;
	padding: 1em;
	transition: 0.25s;
	font-size: 1em;
	cursor: pointer;

	&:hover:not(.submitting),
	&:focus:not(.submitting) {
		border-color: $light-pink;
		background-color: white;
		color: $dark-pink;
		font-weight: bolder;
		transition: 0.25s;
		scale: 1.015;
	}

	&:active,
	&:focus {
		background-color: white;
		color: $dark-pink;
		scale: 1;
	}
}

// @media only screen and (min-width: $desktop) {
//   // insert here
// }

// @media only screen and (min-width: $tablet) {
//   // insert here
// }

// @media only screen and (max-width: $tablet) {
//   // insert here
// }
