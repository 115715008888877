@use './colors.scss' as c;
@use './typography' as t;

*,
*::before,
*::after {
  box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // -webkit-box-sizing: border-box;
  font-size: 14px;
  font-family: t.$font-body;
  color: c.$text-color;
}

*:focus {
  outline-color: c.$outline-color;
}

a {
  text-decoration: none;
}
