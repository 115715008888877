@use "../../../styles/" as s;

.container {
	display: flex;
	flex-direction: column;
	background-color: s.$bg-off-white;
	border: 2px solid s.$primary-color;
	border-radius: s.$photo-card-border-radius;
	box-shadow: s.$photo-card-box-shadow;
	padding: 1em;
	width: s.$photo-card-width; // calc(200px * .9)
	min-width: s.$photo-card-width; // prevent mobile screens from squishing the card and images
	height: s.$photo-card-height; // calc(325px * .9)
}

.title {
	font-family: s.$font-header;
	color: s.$primary-color;
	font-size: s.$photo-card-font-size; // originally 1.75em
	line-height: 1em;
	padding: 0;
	text-align: center;
	transform: rotate(-5deg);
	margin: 1rem 0.25em;
	margin-bottom: 0;
}

@media only screen and (min-width: s.$tablet) {
	.container {
		scale: 1.3;
		margin: 1em;
	}
	.title {
		flex-basis: 25%;
		align-content: center;
	}

	// show at width > 1200px
	// hide at width < 1200px
}

@media only screen and (max-width: s.$tablet) {
	.container {
		scale: 1.1;
		margin: 0.75rem;
	}
	.title {
		flex-basis: 15%;
		font-size: 1.3rem;
	}
}
