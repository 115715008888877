@use "../../../order/input/Input.module.scss";
@use "../../../../styles/" as s;

.invalid {
	border-color: s.$error-color;
}

.icon {
	position: absolute;
	top: 10px;
	right: 10px;
	& path {
		fill: s.$primary-color;
	}
}
