@use '../../styles' as s;

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: auto;
  overflow: scroll;

  & > * {
    float: left;
    margin: .25em;
  }

  @media only screen and (max-width: s.$tablet) {
    display: flex;
    flex-direction: row;
    row-gap: 1em;
    margin: 2em .25em;
    align-items: center;
    justify-content: center;
  }
}

.title {
  font-family: s.$font-header;
  font-size: 5em;
  font-weight: 800;
  color: s.$dark-pink;
}

