@use "../../styles/" as s;

.navbar {
	height: 100%;
	flex: 2 1 80%;
	display: flex;
	column-gap: 0.5em;
	justify-content: flex-end;
	align-items: flex-end;
	margin-right: 10px;

	.link {
		position: relative;
		text-decoration: none;
		background-color: white;
		color: s.$text-color;
		font-weight: 800;
		font-size: 1.2em;
		font-family: s.$font-title;
		margin-bottom: -2px;

		padding: 8px;
		padding-bottom: 5px;
		border: 2px solid transparent;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		transition:
			border-color 0.3s ease-in-out,
			padding-bottom 0.3s ease-in-out;

		&:not(.active):hover {
			color: s.$primary-color;
			border: 2px solid s.$primary-color;
			border-bottom: none;
			padding-bottom: 12px;
		}
	}

	.active {
		position: relative;
		padding-bottom: 20px;
		font-weight: 800;
		color: s.$primary-color;
		text-decoration: none;
		border: 2px solid s.$primary-color;
		border-bottom: none;
		border-radius: 5px 5px 0 0;
		transition: // removes border color from transition
			padding-bottom 0.3s ease-in-out;

		&:focus {
			outline: none;
			text-decoration: underline;
			text-underline-offset: 4px;
			text-decoration-thickness: 4px;
			text-decoration-color: s.$outline-color;
		}
	}

	.active:before,
	.active:after {
		content: "";
		display: block;
		width: 100vw;
		height: 10px;
		position: absolute;
		pointer-events: none;
		border-bottom: 2px solid #e8868e;
		bottom: -2px;
	}

	.active:before {
		border-right: 2px solid #e8868e;
		border-radius: 0 0 5px 0;
		right: 100%;
		box-shadow: white 5px 0 0 0;
	}

	.active:after {
		border-left: 2px solid #e8868e;
		border-radius: 0 0 0 5px;
		left: 100%;
		box-shadow: white -5px 0 0 0;
	}
}

.menu {
	flex: 0 1 10%;
	padding: 5px;
	margin: auto;
	cursor: pointer;
	display: none;

	@media only screen and (max-width: s.$tablet) {
		// tablet = 500px
		display: block;
	}
}

.currentPage {
	display: block;
	padding: 5px;

	@media only screen and (max-width: s.$tablet) {
		// tablet = 500px
		display: block;
	}
}

@media only screen and (max-width: 700px) {
	.navbar {
		font-size: 0.9em;
		justify-content: center;

		a#home {
			display: none;
		}
	}
}
