@use "../../../order/input/Input.module.scss";
@use "../../../../styles/" as s;

.container {
	position: relative;
}

.icon {
	position: absolute;
	top: 10px;
	right: 10px;
	& path {
		fill: s.$primary-color;
	}
}

.invalid {
	border-color: s.$error-color;
}

.save {
	display: none;
	position: absolute;
	top: calc(25% - 2px);
	right: 30px;
	font-size: 0.85rem;
	padding: 2px 20px;
	color: s.$white;
	background-color: s.$primary-color;
	border: 2px solid s.$primary-color;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s;
	z-index: 10;

	&.show {
		display: inline;
	}

	&:hover {
		background-color: s.$secondary-color;
		border: 2px solid s.$secondary-color;
	}
}
