@use "../../../styles/" as s;

$column-gap: 30px;

.container {
  position: relative;
  width: 100vw;
  height: 100%;
  // max-height: 420px; // legacy value - retain for records
  margin: 1em auto;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 20%;
    height: 100%;
    z-index: 4; // may break other parts of site
    // create a blur on the edge of the photos
    background: linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%);
    pointer-events: none;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: scale(-1, 1);
  }

}

.photosContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: $column-gap;
  width: 100%;
  height: 100%;

  // for animating the scrolling between pictures
  overflow: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-color: s.$primary-color transparent;
  scrollbar-width: none;
  // padding: 50vw; // centers the element in the middle
  padding: 0 50vw;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: center;
  }
}

.indicators {
  position: absolute;
  bottom: -2.2em;
  right: 50%;
  transform: translate(50%, -50%);
}

.indicator {
  margin: 0 .25rem;
  padding: 0;
  height: .5rem;
  width: .5rem;
  background-color: s.$primary-color;
  box-shadow: s.$photo-card-box-shadow;
  outline: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all .25s ease-out;

  &.active {
    scale: 1.4;
  }

  &.inactive {
    background-color: s.$secondary-color;
  }
}

@media only screen and (min-width: s.$tablet) {

  $min-height: 30rem;
  .container {
    min-height: $min-height;
  }

  .photosContainer {
    column-gap: $column-gap * 1.5;
    padding: 0 50vw;
    min-height: $min-height;
  }

  .indicators {
    bottom: -2.5em;
  }

  .indicator {
    margin: 0 .5rem;
    height: .5rem;
    width: .5rem;
  }
}

@media only screen and (max-width: s.$tablet) {

  $min-height: 25rem;
  .container {
    margin: 2em auto;
    min-height: $min-height;
  }

  .photosContainer {
    min-height: $min-height;
  }
}