@use "../../../styles/" as s;

.button {
  align-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  line-height: normal;
  outline: none !important;
  user-select: none;
  cursor: pointer;
  z-index: 4;
  transition: all 0.25s ease-out;

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }

  // Dark arrow theme
  &.dark {
    border: 1px solid s.$primary-color;
    background-color: s.$primary-color;

    & > svg > path {
      fill: white;
    }

    &:hover {
      background-color: s.$white;
      border-color: s.$secondary-color;

      & > svg > path {
        fill: s.$secondary-color;
      }
    }
  }

  // Light arrow theme
  &.light {
    border: 1px solid s.$white;
    background-color: transparent;

    & > svg > path {
      fill: s.$white;
    }

    &:hover {
      background-color: s.$primary-color;
      border-color: s.$primary-color;

      & > svg > path {
        fill: s.$white;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.arrow {
  margin: 0;
  font-weight: 800;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;

  & > path {
    fill: white;
  }
}