@use "../../../styles/" as s;

.inputBox {
  @include s.inputComponent;

  // used for hiding address fields
  transition: all .5s ease-in-out;
}

.inputLabel {
  @include s.inputLabel;

  // used for hiding address fields
  opacity: 100%;
  transition: 0.25s,
    opacity 0.25s .25s;
}

.input {
  @include s.inputElement;
}

.input:focus + .inputLabel,
.input.active + .inputLabel {
  top: -6px;
  left: 8px;
  padding: 1px 5px;
  font-size: 0.7em;
  background-color: s.$bg-white;
  color: s.$primary-color;
  font-weight: 800;
}

// used for hiding address fields '.hidden'
.inputBox.hidden {
  visibility: hidden;
  z-index: -1;
  margin-top: -35px;

  .inputLabel {
    opacity: 0%;
  }
}

.textarea {
  resize: none;
  height: 60px;
  padding: 10px;
}

// component ID specific styles
.marginTop10px {
  margin-top: 10px;
}

.height60px {
  height: 60px;
}

// Styles for Error Messages
.inputError {
  @include s.inputError;
}

.inputBox.invalid > .inputLabel {
  color: s.$error-color;
}

.inputBox.invalid :has(+ label.inputLabel) {
  border-color: s.$error-color;
}

@media only screen and (min-width: s.$desktop) {
  // insert classes here
}

@media only screen and (min-width: s.$tablet) {
  .inputBox.hidden {
    margin-top: -40px; 
  }
}

@media only screen and (max-width: s.$tablet) {
  .inputBox.hidden {
    margin-bottom: 0px;
  }
}
