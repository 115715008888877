@use "../../../../styles/" as s;
@use "../Input.module.scss";

.inputBox {
	cursor: pointer;
}

.input {
	text-align: left;
	cursor: inherit;

	&:focus {
		border: 2px solid s.$outline-color;
		outline-color: s.$outline-color;
	}
}

.value {
	position: relative;
	top: 5px;
	left: 1px;
	font-size: 14px;
	cursor: inherit;
}

.selector {
	position: absolute;
	top: 9px;
	right: 10px;
	scale: 0.8;
	transform: rotate(90deg);

	& path {
		fill: s.$secondary-color;
	}
}

.inputBox.invalid .input .selector path {
	fill: s.$error-color;
}

.options {
	@include s.inputElement;
	@include s.scrollbar;
	position: absolute;
	height: auto;
	max-height: 10em;
	padding: 0;
	list-style: none;
	background-color: white;
	overflow-y: scroll;
	top: 0;
	left: 0;
	width: 100%;
	overflow-y: auto;
	cursor: pointer;
	z-index: 10000;
}

.singleOption,
.multiLineOption {
	padding: 6px 12px;
	font-size: s.$input-size; // 14px
	border-bottom: 1px dashed s.$accent-color;
	cursor: inherit;

	&.selected {
		background-color: s.$dark-cream;
		border-bottom: 1px solid s.$accent-color;
	}

	&:hover {
		background-color: s.$light-cream;
		border-bottom: 1px solid s.$accent-color;
	}
}

// For Multi Line Select Options
.multiLineOption {
	display: flex;
	flex-direction: column;
	vertical-align: middle;

	& .optionTitle {
		font-weight: bold;
		color: s.$dark-pink;
		padding: 4px 0 3px 0;
	}

	& .optionDescription {
		font-size: 14px;
		padding-bottom: 4px;
	}
}

.options > li:last-child {
	border-bottom: none;
}

.options > li:first-child {
	border-top: none;
}
