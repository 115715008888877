@use "../../styles/" as s;

.form {
	display: flex;
	flex-direction: column;
	margin: 5vh 5vh 10vh;
	gap: 0.3em 0;
	opacity: 100%;
	transition:
		opacity 0.5s ease,
		display 0s 0.5s; /* Delay display transition */
	transition-behavior: allow-discrete;

	&.hide {
		display: none;
		opacity: 0;
		transition:
			opacity 0.5s ease,
			display 0s 0s; /* No delay */
	}
}

.submitting {
	opacity: 0.5;
	pointer-events: none;
	background: linear-gradient(90deg, s.$accent-color, s.$dark-pink);
	background-size: 200% 100%;
	animation: gradient-flow 3s infinite linear;

	@keyframes gradient-flow {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}

.formErrors {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;

	p {
		color: s.$error-color;
		font-size: 0.9rem;
	}

	.errorList {
		// list-style: none;
		margin: 0 auto;
		padding: 0;
		text-align: left;

		li {
			margin: 0;
			padding: 2px 0;
			color: s.$error-color;
			font-size: 0.9rem;

			span {
				font-weight: 700;
				color: s.$error-color;
			}

			i {
				color: inherit;
				font-size: inherit;
				margin: 0 0.25em;
			}
		}
	}
}

.formTitle {
	margin: 0;
	font-family: s.$font-header;
	padding: 15px 0px;
	font-size: 4em;
	color: s.$primary-color;
}

.formInstructions {
	font-size: 1.2rem;
	margin: 0.5rem 0 2em 0;

	b {
		font-size: inherit;
	}
}

#fname,
#cakeType,
#deliveryOption {
	margin-top: 10px;
}

// Delivery Option must be z-index=1 to properly hide
//the Address fields 'Line 1, Line 2, City'
#deliveryOption {
	z-index: 1;
}

// Submit Styles
.submit {
	@include s.primaryButton;
}

.orderSentButton {
	@include s.primaryButton;
	border-radius: 5px;
	margin: 1rem auto;
	padding: 10px 2rem;
}

.orderSent {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transform: translateY(70px); /* Start slightly above */
	transition-behavior: allow-discrete;
	transition:
		visibility 0s 0.7s,
		opacity 0.7s ease,
		transform 1s ease;

	.orderSentTitle {
		font-family: s.$font-header;
		font-size: 2em;
		font-weight: bold;
		color: s.$primary-color;
	}

	.sentIcon {
		margin: -10px auto;
		path {
			fill: s.$secondary-color;
		}
	}

	.orderSentText {
		margin: 5px 20%;
	}
}
.orderSent.open {
	visibility: visible;
	opacity: 1;
	transform: translateY(100px); /* Move to original position */
	z-index: 1;
	pointer-events: all;
	transition:
		visibility 0s 0s,
		opacity 0.5s ease,
		transform 0.5s ease;
}

// Styles for Desktop
@media only screen and (min-width: s.$desktop) {
	.form {
		width: 40em;
	}
}

@media only screen and (min-width: s.$tablet) {
	#lname,
	#cakeTiers {
		margin-top: 10px;
	}
	// flexHalf
	#fname,
	#lname {
		flex: 1;
		order: 1;
	}
	// flexFull
	#email,
	#phone,
	#cakeFlavors,
	#message {
		flex: 4 0 100%;
		order: 3;
	}
	// flexThird
	#cakeType,
	#addressLineTwo {
		flex: 3 1 50%;
		order: 1;
	}
	// flexFourth
	#cakeTiers,
	#city {
		flex: 1 1 25%;
		order: 2;
	}
	// flexFull
	#deliveryOption,
	#addressLineOne {
		flex: 1 0 100%;
		order: 1;
	}
	// flexHalf
	#orderDate,
	#orderTime {
		flex: 1;
		order: 3;
	}
}

@media only screen and (max-width: s.$tablet) {
	#fname,
	#lname,
	#email,
	#phone,
	#cakeType,
	#cakeTiers,
	#cakeFlavors,
	#deliveryOption,
	#addressLineOne,
	#addressLineTwo,
	#city,
	#orderDate,
	#orderTime,
	#message {
		flex-basis: 100%;
	}
}
