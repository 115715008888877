@use "../../../styles/" as s;

.table {
	width: 100%;
	border-collapse: collapse;
	margin: 1rem 0;
	border: 2px solid s.$primary-color;
	border-radius: 2px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

	th,
	td,
	tr {
		font-size: 1.2rem;
		min-width: 130px;
	}
}

.caption {
	font-size: 1.2rem;
	text-align: left;
	font-weight: bolder;
	color: black;
	margin-bottom: 1rem;
}
.table th,
.table td {
	padding: 0.5rem;
	text-align: left;
}
.table th {
	background-color: s.$primary-color;
	color: white;
	font-weight: bold;
}
.table tr:nth-child(even) {
	background-color: s.$light-pink;
}
.table tr:hover {
	background-color: s.$accent-color;
}
.table .header {
	font-size: 1.5rem;
	font-weight: bold;
	color: white;
}

.note {
	font-size: 1rem;
	font-weight: 400;
	font-style: italic;
	text-align: left;
	text-wrap: wrap;
	max-width: 450px;
}

@media only screen and (max-width: s.$tablet) {
	.table th,
	.table td {
		font-size: 1rem;
		padding: 0.5rem;
		min-width: 100px;
	}
}
