@use "../../styles/" as s;

@mixin heroContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	row-gap: 1em;
	margin: 0;
}
@mixin heroText {
	font-family: s.$font-header;
	font-size: 2em;
	color: s.$dark-pink;
	padding: 0;
	margin: -15px 0;
}
@mixin heroTitle {
	font-family: s.$font-title;
	font-size: 5em;
	font-weight: 500;
	padding: 0;
	margin: -10px 5px 15px;
	text-align: center;

	& > .embolden {
		font-weight: 800;
		color: s.$dark-pink;
		font-family: inherit;
		font-size: inherit;
	}
}
@mixin ctaButton {
	border-radius: s.$input-radius;
	font-weight: 600;
	padding: 0.8rem 1.2rem;
	transition: 0.25s;
	margin: 1em 0.25em;
	min-width: 150px;

	&:hover,
	&:focus {
		font-weight: 800;
		transition: 0.25s;
		scale: 1.025;
		cursor: pointer;
	}

	&:active {
		transition: 2;
		scale: 1;
	}

	a {
		text-decoration: none;
		outline: none;
	}
}
@mixin primaryButton {
	background-color: s.$dark-pink;
	border: 2px solid s.$dark-pink;
	color: white;

	&:hover,
	&:focus {
		border-color: s.$light-pink;
		background-color: white;
		color: s.$dark-pink;
	}

	&:active {
		background-color: white;
		color: s.$dark-pink;

		a {
			color: s.$dark-pink;
		}
	}

	a {
		color: white;
	}
}
@mixin secondaryButton {
	background-color: white;
	border: 2px solid s.$dark-pink;
	color: s.$dark-pink;

	&:hover,
	&:focus {
		border-color: s.$light-pink;
		background-color: s.$light-pink;
		color: white;
	}

	&:active {
		background-color: s.$light-pink;
		color: white;

		a {
			color: white;
		}
	}

	a {
		color: s.$light-pink;
	}
}

.homeContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: calc(100vh);
	overflow: hidden;
	margin: 1rem 0;
}

.ctaButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 1rem 0;
}

// DESKTOP CLASSES & STYLES
.heroContainerDesktop {
	@include heroContainer;
}
.heroTitleDesktop {
	@include heroTitle;
}
.heroTextDesktop {
	@include heroText;
}
.ctaButtonDesktopPrimary {
	@include ctaButton;
	@include primaryButton;
}
.ctaButtonDesktopSecondary {
	@include ctaButton;
	@include secondaryButton;
}
.photoContainerDesktop {
	position: absolute;
	top: 10px;

	&.zero {
		// Top left
		top: -310px;
		left: -280px;
		transform: rotate(-4deg);
		z-index: 2;
	}
	&.one {
		// Middle left
		top: -20px;
		left: -450px;
		transform: rotate(-11deg);
	}
	&.two {
		// Bottom left
		top: 125px;
		left: -210px;
		transform: rotate(13deg);
	}
	&.three {
		// Top right
		top: -300px;
		right: -270px;
		transform: rotate(8deg);
	}
	&.four {
		// Middle right
		right: -430px;
		top: -20px;
		transform: rotate(20deg);
	}
	&.five {
		// Bottom right
		top: 140px;
		right: -190px;
		transform: rotate(-11deg);
		z-index: 2;
	}
	&.six,
	&.seven,
	&.eight,
	&.nine,
	&.ten,
	&.eleven,
	&.twelve {
		right: 20%;
		display: none;
	}
}

// TABLET CLASSES & STYLES
.heroContainerTablet {
	@include heroContainer;
}
.heroTitleTablet {
	@include heroTitle;
}
.heroTextTablet {
	@include heroText;
}
.ctaButtonTabletPrimary {
	@include ctaButton;
	@include primaryButton;
}
.ctaButtonTabletSecondary {
	@include ctaButton;
	@include secondaryButton;
}
.photoContainerAligned {
	// used for both Tablet and Mobile
	position: relative;
}

// MOBILE CLASSES & STYLES
.heroContainerMobile {
	@include heroContainer;
	margin-top: 2em;
}
.heroTitleMobile {
	@include heroTitle;
	font-size: 3em;
	margin: -22px 0 0 0;
}
.heroTextMobile {
	@include heroText;
	font-size: 1.5em;
	margin: -10px 0 0 0;
}
.ctaButtonMobilePrimary {
	@include ctaButton;
	@include primaryButton;
	margin: 2rem auto;
}
.ctaButtonMobileSecondary {
	@include ctaButton;
	@include secondaryButton;
	margin: 2rem auto;
}
// the .photoContainerAligned class is defined in
// the 'TABLET CLASSES & STYLES' section and is used by mobile

@media only screen and (max-width: s.$tablet) {
	.heroText {
		font-size: 1.5em;
	}

	.heroTitle {
		font-size: 4em;
		margin-bottom: 2px;
	}
}

@media only screen and (max-width: s.$mobile) {
	.homeContainer {
		height: fit-content;
	}
	.ctaButtonContainer {
		a {
			font-size: 0.6rem;
		}
		.ctaButtonTabletPrimary,
		.ctaButtonTabletSecondary,
		.ctaButtonMobilePrimary,
		.ctaButtonMobileSecondary {
			min-width: 150px;
			margin: 1rem 0.5rem;
			font-size: 1rem;
		}
	}
}
